var html_classes = document.documentElement.classList;
html_classes.remove("no-js");
html_classes.add("js");
$().ready(function(){
    setMenuHeight();
    setupSlideMenu();
    if ($('form').length){
        $('form').each(function(){
            $(this).addClass('floatlabel-active').validate();
            $(this).find('.js-float-wrap').FloatLabel();
        });
    }
    $('#map-click').click(function(){
        // shield the map on mobile for easy scrolling
        $(this).hide();
    });
    already_in_view();
    // page content heading expand
    $('.expand').each(function(index, el){
        var $el = $(el);
        smoothExpander($el.children('h3.title'), $el, $el.children('h3.title'));
    });
});

function setMenuHeight() {
    var headerTop = document.querySelector('.t-page header.top');
    var banner = document.querySelector('.t-page .banners-home .banner-stage');
    var footer = document.querySelector('.footer-bottom div.flex-end');

    // get header main menu height and distance from top of document
    var headerMenu = document.getElementById('headerMainMenu');
    var headerMenuHeight = headerMenu.offsetHeight;
    var headerMenuTop = headerMenu.offsetTop;
    var totalHeight = headerMenuHeight + headerMenuTop + 20;

    //get footer main menu height
    var footerMenu = document.getElementById('footerMainMenu');
    var footerMenuHeight = footerMenu.offsetHeight;

    if (document.location.pathname !== "/") {
        if (window.getComputedStyle(headerMenu).display === 'block') {
            headerTop.style.minHeight = totalHeight + 'px';
            banner.style.paddingTop = totalHeight + 'px';
            footer.style.minHeight = footerMenuHeight + 'px';
        } else if (window.getComputedStyle(headerMenu).display === 'none') {
            headerTop.removeAttribute('style');
            banner.removeAttribute('style');
            footer.removeAttribute('style');
        }
    } else if (document.location.pathname === "/") {
        if (window.getComputedStyle(footerMenu).display === 'block') {
            footer.style.minHeight = footerMenuHeight + 'px';
        } else if (window.getComputedStyle(footerMenu).display === 'none') {
            footer.removeAttribute('style');
        }
    }
}
$(window).resize(setMenuHeight);

var $animation_elements = $('.animation-element, .page-content h1, .page-content h2, .page-content h3, .page-content p, [data-edit] div, [data-edit] li, .contact-wrapper .opening-hours, .contact-wrapper .site-links, .recaptcha-notice, footer .banner');
var $window = $(window);

function already_in_view() {
    var window_height = $window.height();
    var window_top_position = $window.scrollTop();
    var window_bottom_position = (window_top_position + window_height);

    $.each($animation_elements, function() {
        var $element = $(this);
        var element_height = $element.outerHeight();
        var element_top_position = $element.offset().top;
        var element_bottom_position = (element_top_position + element_height);

        //check to see if this current container is already within viewport
        if ((element_bottom_position >= window_top_position) &&
          (element_top_position <= window_bottom_position)) {
            $element.addClass('already-in-view');
        }
    });
}

function check_if_in_view() {
    var window_height = $window.height();
    var window_top_position = $window.scrollTop();
    var window_bottom_position = (window_top_position + window_height);

    $.each($animation_elements, function() {
        var $element = $(this);
        var element_height = $element.outerHeight();
        var element_top_position = $element.offset().top;
        var element_bottom_position = (element_top_position + element_height);

        //check to see if this current container is within viewport
        if ((element_bottom_position >= window_top_position) &&
          (element_top_position <= window_bottom_position)) {
            $element.addClass('in-view');
        }

        //remove class when scrolled back to top
        if (window_top_position == 0) {
            $element.removeClass('in-view');
        }
    });
}

$window.on('scroll resize', check_if_in_view);
$window.trigger('scroll');

function setupSlideMenu(){

    $('#mobile-menu, .slide-menu h3').click(function(){
        $("body").toggleClass('slide-menu-active');
    });

    $(".slide-menu").on('click','.expand',function(e) {
        e.stopPropagation();
        $(this).closest('li').toggleClass('expand-active');
    });

    $(".slide-menu li").each(function(index, element) {
        var $el = $(element);
        if ($el.find('ul').length > 0) {
            var expander = $('<span class="expand"><i class="nav-arrow"></i></span>');
            $el.append(expander);
            expander.click(function(e) {
                e.stopPropagation();
                $el.toggleClass('expand-active');
            });
        }
    });
}

function smoothExpander($trigger, $target, $placeholder, $outer){
    if ($placeholder){
        $target.css('height',$placeholder.outerHeight()+'px');
    } else {
        $target.css('height','0px');
    }
    $trigger.click(function (){
        if ($outer) {
            // unset others
            $outer.siblings().removeClass('active');
            $outer.siblings().find('.body').css('height', '0px');
            // this
            $outer.toggleClass('active');
            if ($outer.hasClass('active')) {
                //If the browser has been resized, the original height might be inaccurate
                //Create clone without restricted height and see what natural height is.
                $target.css('height','auto');
                $outer.parent().addClass('active'); //items don't match height
            } else {
                $target.css('height', '0px');
                $outer.parent().removeClass('active'); // items match height
            }
        } else {
            $target.toggleClass('active');
            var parents_expand = $target.parents('.expand');
            var parents_expander = $target.parents('.expander');
            if ($target.hasClass('active')) {
                //If the browser has been resized, the original height might be inaccurate
                //Create clone without restricted height and see what natural height is.
                var elem = $target.clone().css({"height":"auto"}).insertAfter($target);
                var height = elem.css("height");
                elem.remove();
                $target.css('height',height);
                if (parents_expand.length > 0 ){
                    parents_expand.each(function(index, el){
                        var $el = $(el);
                        var f_clone = $el.clone().css({"height":"auto"}).insertAfter($el);
                        var f_height = f_clone.css("height");
                        f_clone.remove();
                        $el.css('height', f_height);
                    });
                }
                if (parents_expander.length > 0){
                    parents_expander.each(function(index, el){
                        var $el = $(el);
                        var e_clone = $el.clone().css({"height":"auto"}).insertAfter($el);
                        var e_height = e_clone.css("height");
                        e_clone.remove();
                        $el.css('height', e_height);
                    });
                }
            } else {
                if ($placeholder) {
                    $target.css('height',$placeholder.outerHeight()+'px');
                } else {
                    $target.css('height','0px');
                }
            }

        }
    });
}
